import React from 'react';
import './App.css';

import NavigationBar from './components/NavigationBar.js';
import { Route, Switch } from "react-router-dom";

//Pages
import Home from './pages/Home';
import Buyers from './pages/Buyers';
import Contact from './pages/Contact';
import CindyTaskList from './pages/CindyTaskList';
import Servicespage from './pages/Servicespage';
import Difference from './pages/Difference';
import Leadership from './pages/Leadership';
import Owners from './pages/Owners';
import SignUp from './pages/SignUp';
import Businesses from './pages/Businesses';
import SingleBusiness from './pages/SingleBusiness';
import NewHome from './pages/NewHome';
import Valuations from './pages/Valuations';
import ValuationServices from './pages/ValuationServices';


const App = () => {
  return (
    <React.StrictMode>
    <div className='App'>
      <NavigationBar />
  
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/leadership' component={Leadership} />
          <Route exact path='/sign-up' component={SignUp} />
          <Route exact path='/owners' component={Owners} />
          <Route exact path='/buyers' component={Buyers} />
          <Route exact path='/cindytasklist' component={CindyTaskList} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/difference' component={Difference} />
          <Route exact path='/valuations' component={Valuations} />
          <Route exact path='/businesses' component={Businesses} />
          <Route exact path='/businesses/:slug' component={SingleBusiness} />
          {/* <Route exact path='/businesses/:slug' children={SingleBusiness} /> */}
          <Route exact path='/newhome' component={NewHome} />
          <Route exact path='/servicespage' component={Servicespage} />
          <Route exact path='/valuationservices' component={ValuationServices} />
          <Route exact path='*' render={() => <h1>Page not found</h1>} />
          
          <Route component={Error} />
        </Switch>
   
      </div>
    </React.StrictMode>
  )
};

export default App;