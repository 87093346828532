import React from 'react';
import Footer from '../components/Footer';
import './Pages.css';

export default function Leadership() {
  return (
    <>
      <h1 className='leadership'>LEADERSHIP</h1>
      <div className="container fluid col-lg-8 clearfix">
        <h1>DBI - Leadership</h1>
        <h2> Stocker Travis </h2>
        
        <div className="imageLeft">
          <figure className="figure">
            <img src="./images/stocky04A.jpg" className="figure-img img-fluid" alt="Stocker Travis" width="320" height="196" />
            <figcaption className="figure-caption">Stocker Travis<a href="https://www.trec.texas.gov/apps/license-holder-search/?detail_id=880312805">  LREB</a></figcaption>
          </figure></div>
        <p>Stocker Travis has been a professional primary business consultant specializing in helping clients
          with all of the activities required for a successful business ownership transfer including general business brokerage,
          business valuation and loan origination/procurement for business acquisition projects with or without real estate since 1981.
          Stocker has owned, managed and/or operated several businesses and first learned the mechanics of a small business while managing a
          family liquor store after graduating from college in 1979.</p>
        <p> Stocker holds a Texas Real Estate Brokers License, is a member of the Texas
          Association of Business Brokers, the International Association of Business Brokers and the International Society of Business Appraisers. In 38+ years as a full-time business broker/advisor/consultant,
          Mr. Travis has handled the sale of over 500 companies. Stocker is a graduate of Louisiana State University with a Bachelor of Science in
          Business with a major in Finance.</p>
        <p>When he isn't in the car, on the phone, or at a closing, Stocker enjoys playing drums with the band Mr. CJ's.
          DBI-Texas plans to launch 'Drummer-Cam', a live feed that showcases local drummers in their element.  <a href="https://www.linkedin.com/in/stocker-travis-70113747">Stocker's LinkedIn Profile</a></p>
          
        <h2> Cindy Bleichner </h2>
        <div className="imageRight">
          <figure className="figure">
            <img src="../images/cindy01.png" className="figure-img img-fluid" alt="Cindy Bleichner" width="320" />
            <figcaption className="figure-caption">NOT really Benjamin Disraeli</figcaption>
          </figure>
        </div>
        <p>Cindy Bleichner has over 25 years in organizational strategy, marketing, web computing platforms, their databases, and project leadership.
          She was in charge of marketing and practice management of two worldwide service lines for Arthur Andersen and launched their first engagement
          information system. After leaving the firm to open an Orvis flyfishing shop on a river in Tennessee, she was VP of CRM Services for an international
          call center application company.</p>
        <p>  Whether selling indefeasible rights of usage on a fiber project in Brazil, or helping start-ups maximize their
          efficiency with cloud applications, Cindy finds a way to integrate the needs of the client with valuable solutions. </p>
        <p>
          Cindy is currently charged with helping DBI create more agile and responsive delivery of services for our clients and leads the development of top tier business offerings for our firm.
          When she isn't channeling the former British Prime Minister, Benjamin Disraeli, she is flyfishing or judging Lincoln Douglas debate tournaments. <a href="https://www.linkedin.com/in/cindy-bleichner-27a7a15"> Cindy's Linked in Profile </a></p>
      </div>
      < Footer ></Footer >
    </>
  )
};

