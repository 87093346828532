import React from "react";
import Footer from '../components/Footer';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";


import './Pages.css';

export default function Contact() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    message: Yup.string()
      .max(200, "Too long!")
      .required("Required")
  });

  const handleSubmit = async values => {
    const reqConfig = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values)
    };

    try {
      await fetch(process.env.AWS_CONTACT_FORM_API, reqConfig)
        .then(response => response.json())
        .then(result => {
          const { body } = result;
          console.log(body);
        })
        .catch(error => {
          throw error;
        });
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
     <h1 className='contact'>Contact Us</h1>
            <div className="container fluid col-lg-8 clearfix">
    {/* <div className="form-container" align="center"> */}
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: ""
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          isSubmitting,
          isValid,
          status,
          values
        }) => {
          return (
             
            <Form>
              <p><label htmlFor="name">
                Name:  
                <Field name="name" label="Name" type="text" />
              </label></p>
              <p><label htmlFor="email">
                Email:
                <Field name="email" label="Email" type="email" />
              </label></p>
              <p><label htmlFor="message">
                Message:
                <Field
                  name="message"
                  label="Message"
                  type="text"
                  component="textarea"
                  rows="8"
                />
              </label></p>
              <p> <input type="submit" name="Submit" /></p>
            </Form>
        
          );
        }}
      </Formik>
   
    </div>
  
    <Footer></Footer>
    </>
  )
};
