import React from "react";
import Business from "./Business";
const BusinessesList = ({ businesses }) => {
  if (businesses.length === 0) {
    return (
      <div className="empty-search">
        <h3>unfortunately no businesses matched your search parameters</h3>
      </div>
    );
  }
  return (
    <section className="businesseslist">
      <div className="businesseslist-center">
        {businesses.map(item => {
          return <Business key={item.id} business={item} />;
        })}
      </div>
    </section>
  );
};

export default BusinessesList;
