import React from 'react';
import { ButtonOwners } from './ButtonOwners';
import { ButtonBuyers } from './ButtonBuyers';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/smallbiz.mp4' autoPlay loop muted />
      <h1>Back To Work</h1>
      <p>Serving Texas Business Owners in Confidential Business Transfers since 1981</p>
      <div className='hero-btns'>
        <ButtonOwners
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          Business Owners
        </ButtonOwners>
        <ButtonBuyers
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          Business Buyers <i className='far fa-play-circle' />
        </ButtonBuyers>
      </div>
    </div>
  );
}

export default HeroSection;
