import React from 'react';

import ExampleChartBar from '../components/ExampleChartBar';
import Footer from '../components/Footer';

import './Pages.css';

export default function Valuations() {
  return (
    <>
      <h1 className='valuationservices'>VALUATIONS</h1>;
      <div class="container fluid col-lg-8 clearfix">
        <h2>DBI - Small Business Valuations</h2>
        <p> Business Valuations </p>
       
        <p>Our valuations practice has just started as its own entity. We believe the valuation process examples to potential
          sellers not just the worth of their business but the joint effort helps us in understanding and communicating their unique strengths. Anyone can push a button and 
          print out a chart, but taking the time to explain how the pieces fit is an important part of our experience and level of care. Listening to our clients share 
          their stories gives dimension and context to the numbers on the statements and tax forms. 
        </p>
        <p>If you have ever given your life's work to a faceless valuator, only to get one number and a whole lot of mumbo jumbo back from them without them even understanding what 
          it is you do -- well, you understand where we're coming from.  Sure a valuator needs access to mountains of expensive research, the ability to sort through different databases and separate out 
          the facts most representative of their client's business, but without involving the client in the process, the valuator could be way off the mark. </p>
         <p> A classic valuation attempts to embody the worth of the business by viewing it from three major and very different perspectives.  At DBI we add a fourth, the most critical 
          one, the client themselves. The involvement of both the valuator and the client especially during the modeling phase allows for discussion, and highlights any assumptive 
          errors that may have been made. Clients involved in the valuation modeling also understand more clearly how the numbers are used to represent value, which usually inspires them 
          towards a position of strength in their corporate endeavors.  </p>
          <p>These days artificial intelligence seems to be replacing good old fashioned know how and hard work. And while it is handy especially with big data, and fast moving information, 
            be leary of grossed up, quick and dirty valuations and valuators who won't show you how they arrived at their "number", 
            let alone actually work a model on the fly to show you the potential of your business. </p>
          <p>Your business valuation is like your credit score and net worth all wrapped up into one. Take the time to have it developed properly, </p>

      
      <div className='valuation-container'>


        <ExampleChartBar />
      </div>


        <Footer />
        </div>
    </>


  )
}
