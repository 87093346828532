import React from 'react';
import Footer from '../components/Footer';
import './Pages.css';

export default function Difference() {
  return (
  <>
    <h1 className='difference'>DIFFERENCE</h1>;
   <div class="container fluid col-lg-8 clearfix">
      <h1>DBI - How We Are Different</h1>
        <h2>Real People, Real Place.</h2>
        <p>Although this must seem obvious, the truth is it is easier than ever before to create the illusion of a "real
          people" working
          in a "real place." For our part, one of the ways we reassure you we exist is to link our Google Maps address listing to prove we exist at the office we have
          listed. Here is our Google Map link (not just the image of a map) that proves our existence at least to Google. You can check out our address
          <a href="https://g.page/r/CUV0h698Otd6EAE"> here</a></p>

        <h3>Real People You Can Chat, Email, Call, or Video Conference.</h3><p> Well, the place is important, but if office personnel are in the field or in a location
          physically not near you what difference does it make if you can drop by and see them? After all, people run lean organizations these days which is all
          well and good until you need to talk to somebody. At DBI we use technology to our advantage to make it easy to contact us through a variety of channels.
          If you can't get one of us on the line, someone is there to take a message and make sure your needs are addressed. Whether you are a prospective buyer,
          an owner in the process of selling or a bank requesting further information for current transaction, we are here for you. You'll see many sites with a lists
          of people seemingly at the ready, but if they don't share information between them, or if you, the client, are only left with a voicemail option when you
          need to talk now...all those people are hardly helpful.</p>

        <h3>Proven, established processes linked to new technologies make it easier than ever to transact business.</h3>
        <p> There is no doubt about it, matching buyers and
          sellers is just the very beginning of business brokering and one that is enhanced by the internet. But that is the tip of the iceberg. DBI partners with
          Docusign to make the process of Listing, servicing NDAs, and negotiating contract terms easier on both buyers (especially those internationally) and sellers
          here in Texas. There is no doubt our lifestyles have become more mobile, but in response to the COVID pandemic, having a way to meet "virtually" and then
          legally sign or red-line documents electronically among all parties involved has been a relief and an important feature in a "contactless" world.</p>

        <h3>Experience is critical and DBI has worked with some of the best brokers in Texas.</h3><p> It takes years and a lot of hard learned lessons to become a good
          broker. It helps to be mentored by the very best in the business. While you don't have to have a real estate license in Texas to be a business broker,
          you will surely have to liase with someone who does if you are selling real estate with the business. Stocker Travis is a licensed real estate broker
          and has had his license since 1987. That is a long time in commercial real estate (not selling houses..). Go to the TREC site and look up any broker
          and view their license history (to view when they received their license you have to print the transcript from that page). Whether negotiating leases,
          or brokering a business with real estate in the buy/sell transaction -- you want a broker with real estate experience and licensing.</p>

        <h3>DBI has done business with Texas banks for over 30 years.</h3><p> Financing is a must in most larger transactions.  Ask your broker what their last financed
          transaction was and what bank it was with, and who the broker worked with there. Then call the bank. It takes great skill and understanding in managing
          complex SBA loans requirements and conventional loans. It is important to select a broker who understands the SBA standards of procedure, and works to
          assist the bank and buyer towards a successful closing. When possible, we list ours on our site in the businesses section. It is a matter of pride and
          celebration to work with professional bankers, buyers and sellers to effectuate a sale.</p>

        <h3>The best closing attorneys</h3>
        <p>The final countdown -- our closing attorneys. Lenders work with vetted closing attorneys experienced in commercial real estate and SBA transactions.
          No broker gets to the goal line without their closing attorney. DBI's larger transactions use the best in Dallas, 
          <a href="https://ntaggl.org/associates/colven-tran-meredith-pc/">Colven Tran and Meridith, P.C.</a> and our colleague there, Billi Jo Hummel is the best
          in the business. Before you select a broker to handle your business transaction, ask them who they use as their closing attorneys -- then call that firm
          and confirm the breadth and length of time that broker has had a relationship with them. Choose someone with experience and longevity in the Texas market.</p>

    </div>
      <Footer></Footer>
</>

  )
};
