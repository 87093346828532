import React from "react";
import { Link } from "react-router-dom";
import defaultImg from "../images/business-1.jpg";
import PropTypes from "prop-types";
import { memo } from "react";

const Business = memo(({ business }) => {
  const { name, slug, images, price } = business;
  // console.log(name);
  return (
    <article className="business">
      <div className="img-container">
        <img src={images[0] || defaultImg} alt="single business" />
        <div className="price-top">
          <h6>{new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(price)}</h6>
          <p></p>
        </div>
        <Link to={`/businesses/${slug}`} className="btn-primary business-link">
          features
        </Link>
      </div>
      <p className="business-info">{name}</p>
    </article>
  );
});

Business.propTypes = {
  business: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    price: PropTypes.number.isRequired
  })
};
export default Business;
