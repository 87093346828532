import React from "react";
import ReactDOM from "react-dom";
//import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import { BusinessProvider } from "./context";
import App from "./App";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

ReactDOM.render(

  <BusinessProvider>
    <Router>
      <App />
    </Router>
  </BusinessProvider>
   ,

  document.getElementById("root")
);
