import React from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import BusinessesContainer from "../components/BusinessesContainer";

const Businesses = () => {
  return (
    <>
      <Hero hero="businessesHero">
        <Banner title="our businesses">
          <Link to="/" className="btn-primary">
            return home
          </Link>
        </Banner>
      </Hero>
      <BusinessesContainer />
    </>
  );
};

export default Businesses;
