import React from "react";
import { withBusinessConsumer } from "../context";
import Loading from "./Loading";
import BusinessesFilter from "./BusinessesFilter";
import BusinessesList from "./BusinessesList";

function BusinessesContainer({ context }) {
  const { loading, sortedBusinesses, businesses } = context;
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <BusinessesFilter businesses={businesses} />
      <BusinessesList businesses={sortedBusinesses} />
    </>
  );
}

export default withBusinessConsumer(BusinessesContainer);

// import React from "react";
// import { BusinessConsumer } from "../context";
// import Loading from "./Loading";
// import BusinessFilter from "./BusinessFilter";
// import BusinessList from "./BusinessList";
// export default function BusinessContainer() {
//   return (
//     <BusinessConsumer>
//       {value => {
//         const { loading, setBusiness, sortedBusiness,business } = value;
//         if (loading) {
//           return <Loading />;
//         }
//         return (
//           <>
//             <BusinessFilter business={business} />
//             <BusinessList business={sortedBusiness} setBusiness={setBusiness} />
//           </>
//         );
//       }}
//     </BusinessConsumer>
//   );
// }
