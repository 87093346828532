import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { GrBarChart, GrConfigure, GrOptimize, GrMoney } from "react-icons/gr";
import Title from "./Title";
import './Services.css';

export default class Services extends Component {
  state = {
    services: [
      {
        icon: <GrBarChart />,
        link: "/buyers",
        title: "Finance Consulting and Packaging",
        info:
          "DBI's breadth of experience in the market can support financing needs, whether selling a business, acquiring a business or new product financing."
      },
      {
        icon: <GrConfigure />,
        link: "/difference",
        title: "Small Business Advisory Services",
        info:
          "DBI supports business transitions by consulting on exit strategies, to help drive interest and support due diligence efforts."
      },
      {
        icon: <GrOptimize />,
        link: "/owners",
        title: "Small Business Valuation",
        info:
          "DBI provides small businesses and banks with a full range of valuation services by credentialed professionals."
      },
      {
        icon: <GrMoney />,
        link: "/businesses",
        title: "Private Business Listings",
        info:
          "DBI has developed channels to privately advertise businesses for sale in addition to typical marketing and advertising."
      }
    ]
  };
  render() {
    return (
      <section className="services">
        <Title title="services" />
        <div className="services-center">
          {this.state.services.map(item => {
            return (
              <article key={`item-${item.title}`} className="service">
               <Link to={`${item.link}`} >
               <span> {item.icon} </span> </Link>
                <h6>{item.title}</h6>
                <p>{item.info}</p>
              </article>
            );
          })}
        </div>
      </section>
    );
  }
}
