import React from 'react';
import Footer from '../components/Footer';

import './Pages.css';

export default function Owners() {
  return (
    <>
    <h1 className='owners'>OWNERS</h1>
   
    <div className="container fluid col-lg-8 clearfix">
      <h1>DBI Small Business Owner Services</h1>
        <h2>Strategic Services - The Needs Analysis - Short Term Pricing Action Plan</h2>

        <h3>A Business Assessment, Appraisal and Strategic Review</h3>
       <p>Nobody wants to be in a situation where they sell because they feel forced.
        For that reason, every business owner should take advantage of our NEEDS ANALYSIS. to understand at the <i>current time</i> what the
        price and plan is for a sale, if they had to do it now, REGARDLESS OF OR IN CONJUNCTION WITH AN EXIT PLAN.</p>
        <p>   Most people come to us to sell their business. 
          You probably know how much you would <i>like</i> to get for the sale, and probably how much you <i>NEED</i> to profit from the sale.
          Sadly, neither of these amounts may be reasonable. By reasonable, we mean <i>marketable</i> given the current condition of your business and the
          market's willingness to pay the price you would like to get. The market's willingness is made up of all sorts of things at the current moment,
          not only comps of similar recent sales, but also inflation, bankability of your cash flow and available buyers in your particular industry.</p>

            <p>  Often people comment that business must be good for DBI when the economy is bad because that is when owners want to sell their businesses, after all, if the business 
            was a good one why would any owner sell? This is remarkable because historically, DBI inventory has largely been made up of capable businesses whose owners, 
          for all sorts of reasons other than distress, choose to sell.</p>
        <p> Whether your sale is planned or because of
          an unexpected event, an understanding of your business value, opportunity and the qualifiers of success will give you confidence during the
          transaction process. Too often, a family circumstance or unexpected event puts a temporarily incapacitated Owner, uninformed officers, or family members in 
          the position of having to manage a business they are not prepared to handle. It is good insurance to have a plan of action with guidelines for deciding when to sell and at what price. </p>

        <h3>Why use DBI Business Advisors for a Needs Analysis?</h3>
        <ul >
          <p><li>It is confidential, we are not a faceless entity selling you a generic valuation, (and your information along with it). We back this up with an NDA
          clause in our contract for work. At this point, we are not listing your business, we are advising you as to the price and possible strategies for a sale should you
              decide to sell soon.</li>
          <li>We work only for you and with you, we are not your banker, nor your accountant, nor your lawyer, nor your financial planner -- we sell businesses, so we know what is selling and what isn't. 
          Bankers assist with the financing of certain transactions, your accountant manages and remarks on the corporate finances, your lawyer ensures your contractural obligations, 
          your financial planner may advise on the growth of your capital,
           - but not one of them manages the whole business sale transaction and qualifies buyer leads.  </li>
          <li>We have decades of experience in the various aspects of selling businesses in Texas both with SBA preferred lenders and with other means of financing. We know what banks 
          look for and what doesn't work.  Unlike most generic valuation reports -- the Needs Analysis will tell you what <i>will</i> work and give you targets and tactics in order for you to reach
            your goals as well as prepare you for an emergency plan of action and price in the face of catastrophic circumstances.  We know of no one else offering this.</li>
          <li>The price assessment and strategic directives are based upon your individual needs and those of the business. The report sets forth guidelines based upon
          interviews between you and your DBI Advisor that you can use with any broker, not just us. Of course, when you are ready to sell we would love for you
          to list with us, but that is not a limitation of this strategic report.</li>
         <li> We have a secure upload process and access methods that log every activity and prohibit use without passcodes (which are time sensitive and
              expire) This protects your identity and information from misuse.</li></p></ul>


         <h3> What's in it for you?</h3>
        <p> No owner should rush towards listing their business without a NEEDS ANALYSIS. There are plenty who will be happy to take your money for a listing,
          knowing full well it has little chance of a successful transaction. A number of understandings are revealed from the NEEDS ANALYSIS:</p>
<ul>
       <ol> <p><li> An understanding of the current marketable price for your business and the "bubble of reasonableness"</li>
          <li>Strength or Weakness in your Record Keeping (red flags that need to be investigated)</li>
          <li>Impact of Cash Flow Trends after Seller Discretionary Earnings</li>
          <li>Effect of the Debt to Equity ratio and its impact on the profitability of the business sale</li>
          <li>Possible financial recasting and its benefit on the asset value</li>
          <li>Trend analysis (and customer churn if appropriate)</li>
          <li>"Riskiness" of your business offering (dying industry, too innovative, too small, etc.)</li>
          <li>Three pricing and sales strategies considering your needs and wants and their outcome for a current sale
              (within 1 year)</li></p></ol></ul>
          <p>Your DBI Advisor will have a face to face or Zoom video conference with you to go over your results and work with you in scenario building.</p>

          <p>Mature businesses with owners looking to stay the course until retirement, will understand what their potential profitability is for a current sale 
            and if that fits with their retirement expectations or understand what needs to change and how to get it there. Current profitable businesses can 
            assess whether or not their business is in a growth, sustainable, or waning aspect and relate that to the marketability of a sale. Businesses in 
            industries under-going consolidation may assess the potential risk of staying the course or cashing in. Enterprises with debt to equity ratios that 
            make a profitable sale difficult may decide to restructure their debt or liquidate.</p>

          <p>Most business owners were once buyers of their business (as opposed to inheriting it - or starting from scratch), so many have all been through the 
            process of buying a business and perhaps financing it. But market trends change quickly and even though you may have gone through the SBA process five 
            years ago, a lot has changed since then. New guidelines, banking regulations, coupled with the national financial landscape (not to mention COVID) can change 
            what was once a profitable business into a mediocre one. Consider having a strategic NEEDS ANALYSIS review done at least once every three years so you are 
            prepared and adjust to market conditions.</p>

          <p>To get the process started get out your checkbook and provide the following:</p>
        
          <ul><p>         
            <li> A signed commitment of engagement for for businesses between $300,000 and $2,000,000 net income. The cost of a Needs Analysis is $2,500.</li>
            <li>
              <ul>
              <li>A link to an individual, secure workspace to upload the following:
                <ul>
                <li>At least 3 years tax returns</li>
                <li>Balance Sheet</li >
                <li>Current Income Statement</li>
                <li>Lease Contract and / or Real Estate Property Description</li >
                <li>Vendor Contracts / Agreements to be assigned or transferred(product, leases, and franchise if warranted)</li >
                <li>Furniture and Fixtures List</li >
                <li>Inventory approximate value</li >
                <li>Business Equipment List</li >
                <li>Vehicles List used for the Business with payoff amount if applicable and current debt structure or lease agreement</li >
                  <li>For non - retail services businesses provide:
                    <ul>
                    <li>product list with gross revenue and net revenue for 3 years</li >
                      <li>customer (number masked, we don't want the names) by product list with gross revenue and net profit for 3 years</li>
                    </ul>
                  </li>
              </ul>
              </li>
              </ul></li>
            
          <li>An initial interview with us (on site or via zoom) to understand your needs and wants</li ></p>
          </ul>
         <h2> Brokerage Services - Selling Your Business with DBI</h2>
        <p>We are not like other brokerage firms. For one, <u>all of our listings are exclusive.</u> That means you chose us as your only broker while our listing 
            is in force. This protects you from any confusion in the marketplace. Any changes you and your DBI broker make to your listing are populated on 
            all the advertised sites, en mass. With different brokers, the message gets muddled and changes are made in a haphazard way. Also, having only one 
            broker means the same buyer doesn't keep asking for information on the same listing multiple times. Buyers need to be assured they are talking to a 
            knowledgeable source for the listing. Having multiple brokers with the same buyer hitting all of them causes even more confusion in regards to your 
            listing. Therefore, we have found it is important to have exclusive listings with our clients.</p>
       <p>
          We ask you <u>pay for advertising and marketing fees upfront at the time you list with us and every 6 months at a time thereafter</u>. DBI advertises your opportunity
          with a number of high traffic sites who charge us for 6 months at a time. Although you would pay a much higher rate if you advertised with them
            yourself - there are still costs in setting up the advertising and monitoring the traffic.</p>
          <p><u>We do not co-broker.</u> Differences in the process of managing transactions can sometimes accidentally cause confidential information to be 
            leaked and private information to be exposed. For this reason, and the need to ensure quality, we have found it beneficial not to co-broker.</p>
        <p>We expect you to <u>have NEEDS ANALYSIS completed within a 12 month timeframe</u>. Your DBI Broker cannot list your business unless you have agreed to a plan of 
            attack and an initial listing price.</p>
        <p><u>We have milestone meetings no less than every 3 months with you to formally review your tactics and price.</u> Your DBI Broker works for the business
          transaction. Their sole role is to get this transaction over the finish line. Our brokers are not judgemental and remain open to the initial price and
          strategy you and your DBI Advisor worked on, but there are limits. If your price is not negotiable and outside the "bubble of reason" the likelihood
          of it selling within 6 - 9 months is far less than if it is within the bubble. That doesn't mean it won't sell. At some point you may find a buyer,
          but you will have to pay advertising and marketing fees every 6 months to do so, while burning through buyers who would have considered it at a
          different price but who have now moved on to buy
          something else. Our brokers will share with you, real time, what buyers are saying about the listing after their initial interview and again after any
          scheduled site visits. This information and the broker's experience will assist you in fine tuning your previous plan.</p>
          
        
<h3>          What's in it for you?</h3>
       <p>Listing with DBI-Business Advisors</p>
         
         <ul><ol><p>
          <li>All Listings include advertising on multiple sites</li>
          <li>Business Summary and NDA for Prospective Buyers through Docusign</li>
          <li>Pitch Deck included for engagements over $300,000</li></p></ol></ul>

        <p>Buy Sell Transaction Services </p>

        
          <ul><ol><p>
          <li>Buyer Qualifying</li> 
          <li>Buy Sell Intermediary Services</li> 
          <li>Contract Services</li> 
          <li>Attorney Referral</li> 
          <li>Lease Negotiations </li>
          <li>Transaction Financing Procurement and Fulfillment</li> 
          <li>Closing Services</li> 
          <li>Post-Sale Transition Assistance </li></p></ol>
            </ul>
          <p> To get the Sales process started - call us! </p>
        
        <p> Check out our <a href="https://dbi-businessadvisors.com/businesses/auto repair"><b>Auto Body and Paint</b></a> opportunity under the Our Businesses tab.</p>
         
    </div>
    
    
    <Footer></Footer>
    </>
  )
  
};
