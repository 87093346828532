import React from 'react';
import Footer from '../components/Footer';
import './Pages.css';

export default function CindyTaskList() {
    return (
        <><h1 className='cindytasklist'>CindyTaskList</h1>;
      <div class="container fluid col-lg-8 clearfix">
        <h2>Major Scaffolding</h2>
        <h3> Valuation Contract Maker and Integration</h3>
        <ol>
        <ul>Broker Price Opinion</ul>
        <ul>Business Price Evaluation - Market Snapshot - Needs Analysis</ul>
                    <ul>Valuation Assessment - Disaster Steps and Planning with Valuation Updates and Listing Component</ul></ol>
        <h3> Market Snapshot Constructor</h3>
        <p>At DBI, we recognize the importance of confidentiality for both our Sellers and any of our Prospective Buyers. For that reason, we don't ask for all of your 
        pertinent information at the very beginning of the business procurement dance. Instead, we work with different levels of interest. As a prospect progresses
         through each level, more in depth information about the business opportunity will be revealed in exchange for more in-depth information from the Buyer. At any level, except the 
         very last one, the Buyer can indicate his lack of interest and give us a reason for not going further, which we feed back to the Seller.  </p>
         <h3>Lvl-1</h3>
         <p>The very first level. Many of our prospects come from advertisments and targeted email marketing. If those Buyers are interested, they will call us or send an email 
         via one of the advertiser sites. We have only the Prospect's email at this point - and they only have the advertisement. After they have contacted us, we send them an invitation to fill out 
         our NDA.</p>
        <h3>Lvl-2</h3>
        <p>A Non-Disclosure contract is sent to the email and name DBI received from the advertisement inquiry. Prospects who fill in the NDA via Docusign fully and sign it, including most importantly the phone number and 
        address fields, will be scheduled for an interview with the lead broker handling the particular engagement. The NDA includes a business summary, which 
        usually has more information, but at least has the full listing information. </p>
        <h3>Disaster Planning Constructor and Integration</h3>
        <p>The interview with the advisor usually happens within a day or two of having received the NDA. The interview is by phone and is designed to determine the ability and sincere interest of the
        prospective buyer, not only financially, but also in regards to the prospect's suitability to the particular offering (for example, Restaurant experience if buying a restaurant). Also 
        necessary is to understand the amount of financing required (if any) and the bankability of the prospect. At this level you can ask general questions about the opportunity, or discuss with the 
        broker the things you WOULD NOT be intereted in. Use this time to vet the project according to your standards, for instance, how much do you need for this business to generate if you are to 
        live off of the proceeds.</p>
        <h3>Lvl-4</h3>
        <p>If you pass the interview process, and you are still interested in the opportunity, the Advisor will ask you to fill out a Buyer's Questionnaire. The questionnaire is an in-depth profile of your 
        financial capabilities and experience. Once received and approved with the Advisor, we will ask the Seller to authorize access to the Financials (not tax returns, but the Financials) via our secure site area in order 
        to further analyze the opportunity.</p>
        <h3>Listing Makers</h3>
        <h3>Business Summary Maker</h3>
        <p>An interest at Level 5 shows a willingness and ability to purchase the business but without any negotiations on price and terms. At this point you will be scheduled for a 
        site visit or zoom call with the Owner. And you will receive more in-depth information regarding product offerings, operational costs, and personnel. You will be asked to show your 
        identification before your zoom video or physical site visit which should match your original NDA. You will be given a boilerplate Offer to Purchase contract to be thinking about as you 
        tour the facilities or meet with the Seller.</p>
        <h3>NDA Maker</h3>
        <p>Congratulations, you've reached the end of the Buyer Funnel. At this point you have all the relevant information you need to make a <i>contingent</i> offer to purchase. Hopefully, your Advisor has ferreted out any rough spots -- making your way through financing fairly smooth. Your Advisor will work with you in negotiating terms between 
        you and the Seller. Remember, your Offer is CONTINGENT on a variety of factors like financing, and due diligence. But this is only the beginning of the Buying Process - and one of the reasons 
        having a seasoned broker is so important. </p>
        <h3>Advertiser Generator</h3>
        <p></p>



      </div>





      <Footer></Footer>
    </>


  )
};