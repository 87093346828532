import React from 'react';
import './Footer.css';
//import { Button } from './Button';

import {
  Navbar,
  Container,

} from "react-bootstrap";



function Footer() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" bottom="fixed">
        <Container>
      <div className='footer-container' align="center">
        <div className="row">
          <div className='footer-links'>
            <div className='footer-link-wrapper'>
             <div className='ms-auto'>
                <div className='footer-link-items'>
                  <h3>About Us</h3>
                      <a href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-5_0.pdf">Texas Real Estate Commission Consumer Protection Notice </a>
                      <a href="../images/IABS-1-0.png">Texas Real Estate Commission Information About Brokerage Services </a>
                    <p></p>
                    <p>OFFICE LOCATION TEXAS:</p>
                          
                      <a href="https://g.page/r/CUV0h698Otd6EAE">15851 Dallas Parkway #600</a>
                      <p>Addison, TX 75001</p>
                     
                </div>
                </div>
              
              <div className="me-auto">
                <div className='footer-link-items'>
                  <h3>Contact Us</h3>
                  {/* <a href="../contact">Contact</a> */}
                    
                  <p><a href="+1-833-717-7559">833-717-7559</a></p> 
                  <p><a href="https://g.page/r/CUV0h698Otd6EAE">Google Maps</a></p>            
                </div>
              </div>
              <div className="me-auto">
                <div className='footer-link-items'>
                <h3>Street Creds</h3>
                  <p>DBI Business Advisors Memberships</p>
                      <a href="https://www.memberleap.com/members/directory/search_bootstrap.php?org_id=TABB"> Member of Texas Association of Business Brokers </a>
                      <a href="https://www.ibba.org/find-a-business-broker/">International Business Brokers Association </a>
                </div>
              </div>
            </div>
          </div>
      
        <div className="row" align="center">
          <small className='website-rights'>DBI © 2024</small>
        </div>
      </div>
      </div>
    </Container>
  </Navbar>
      
    </>
      )
  }
  

export default Footer;