import React, { Component } from "react";
import defaultBcg from "../images/business-1.jpg";

// import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import { BusinessContext } from "../context";
import StyledHero from "../components/StyledHero";

//import AwardIcon from "../components/AwardIcon";

export default class SingleBusiness extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      slug: this.props.match.params.slug,
      defaultBcg: defaultBcg
    };
  }
  static contextType = BusinessContext;

  // componentDidMount() {
  //   console.log(this.props);
  // }
  render() {
    const { getBusiness } = this.context;
    const business = getBusiness(this.state.slug);

    if (!business) {
      return (
        <div className="error">
          <h3> no businesses could be found...</h3>
          <Link to="/businesses" className="btn-primary">
            back to businesses
          </Link>
        </div>
      );
    }
    const {
      name,
      description,
      established,
      size,
      price,
      active,
      sold,
      onhold,
      offmarket,
      otr,
      adlink,
      ndalink,
      grosssales,
      cashflow,
      inventoryatcost,
      ffe,
      // extras,
      busValuation,
      eqValuation,
      images
    } = business;
     // eslint-disable-next-line
    const [main, ...defaultImages] = images;
    console.log(defaultImages);

    return (
      <>
        <StyledHero img={images[0] || this.state.defaultBcg}>
          <Banner title={`${name} business`}>
            <Link to="/businesses" className="btn-primary">
              back to businesses
            </Link>
          </Banner>
        </StyledHero>
        <section className="single-business">
          <div className="single-business-images">
            {defaultImages.map((item, index) => (
              <img key={index} src={item} alt={name} />
            ))}
          </div>
          <div className="single-business-info">
            <article className="desc">
              {/* <p className=".desc">{description}</p> */}
               <h3>Details</h3> 
                
                  {description.map((item, index) => (
                    <ul className= "desc" key={index}> {item}</ul>
                  ))}
            </article>
            <article className="info">
              <h3>info</h3>
              <h6>
                <b>price:</b> {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
                }).format(price)}</h6>
              <h6><b>gross sales:</b> {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(grosssales)}</h6>
              <h6><b>cash flow:</b> {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(cashflow)}</h6>
              <h6><b>inventory:</b> {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(inventoryatcost)}</h6>
              <h6><b>FFE:</b> {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(ffe)}</h6>
              <h6><b>Size:</b> {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(size)} SQFT</h6>
              <h6>
                <b>Established:</b>
                {established > 1 ? ` ${established} years` : `${established} year or less`}
              </h6>
              <h6>{busValuation && `Real Estate Available - not included in price `}
                </h6>
              <h6>{eqValuation && `* Can be Relocated`}
              </h6>
              <h6><b>Status:</b> {active && `Active Listing`}
                {sold && `SOLD`}
                {onhold && `Business On Hold`}
                {offmarket && `Business Off Market`}
                {/* {private && `Business Private Listing`} */}
                {otr && `Contract Pending`}</h6>
              <h6><b>{adlink && `Advertising link:`}</b>
               {adlink && <a href={adlink}>BizBuySell, BizQuest or LoopNet</a>}
              </h6>
              <h6>{ndalink && <a href={ndalink}>Sign NDA for more info</a>}
              </h6>
            </article>
          </div>
        </section>
        
       {/*  { <section className="business-extras">
          <h6>extras </h6>
          <ul className="extras">
            {extras.map((item, index) => (
              <li key={index}>- {item}</li>
            ))}
          </ul>
        </section> } */}
      </>
    );
  }
}
