import * as React from "react";
import { Chart } from "react-google-charts";

const ExampleChartBar = () => {
    return (
        <Chart
            chartType="BarChart"
            data={[
                ['Industry', 'Number of Valuations'],
                ['Automotive',15],
                ['Courier services',10],
                ['Daycares',3],
                ['Distribution & Manufacturing',18],
                ['Dry cleaning',10],
                ['Graphic',12],
                ['Hardware Stores',4],
                ['HVAC',8],
                ['Landscape',7],
                ['Liquor & Convenience',9],
                ['Medical',5],
                ['Personnel Agencies',18],
                ['Pet Services',17],
                ['Printing',14],
                ['Restaurant & Fast food',24],
                ['Security',2],
                ['Sign',16],
                ['Specialty Food',15],
                ['Trailer Manufacturing',12],
                ['Travel Agencies',4],
                ['Trophy Shop',14],
                ['Trucking & Transportation',25],
                ['Retail & Service companies',55],
                ['Vending',54],

            ]}
            options={
                // Chart options
                {
                    title: "DBI Valuation and Consulting Engagements",
                    colors: ['#b0120a'],
                    hAxis: {
                        title: "",
                        viewWindow: { min: 0, max: 100 },
                        textStyle: {
                            fontsize: 8
                        },
                    },
                    vAxis: {
                        title: "Industry",
                        textStyle: {
                            fontsize: 8
                        },
                    },
                    legend: "none",
                    animation: {
                        startup: true,
                        easing: 'linear',
                        duration: 3500,
                    },
                    enableInteractivity: false,
                }
            }
            width={"100%"}
            height={"1000px"}
            bar={'color: red'}
            
        />
    )
}
export default ExampleChartBar;