import React from "react";
import { useContext } from "react";
import { BusinessContext } from "../context";
import Title from "./Title";
// get all unique values
const getUnique = (items, value) => {
  return [...new Set(items.map(item => item[value]))];
};

const BusinessesFilter = ({ businesses }) => {
  // react hooks
  const context = useContext(BusinessContext);
  const {
    handleChange,
    type,
    established,
    price,
    minPrice,
    maxPrice,
    minSize,
    maxSize,
    busValuation,
    eqValuation 
  } = context;

  // get unique industry
  let types = getUnique(businesses, "type");
  // add all
  types = ["all", ...types];
  // map to jsx
  types = types.map((item, index) => (
    <option key={index} value={item}>
      {item}
    </option>
  ));
  // get unique was years established  list
  let people = getUnique(businesses, "established");
  people = people.map((item, index) => (
    <option key={index} value={item}>
      {item}
    </option>
  ));
  return (
    <section className="filter-container">
      <Title title="search businesses" />
      <form className="filter-form">
        {/* select type */}
        <div className="form-group">
          <label htmlFor="type">industry</label>
          <select
            name="type"
            id="type"
            onChange={handleChange}
            className="form-control"
            value={type}
          >
            {types}
          </select>
        </div>
        {/* end of select Business Type */}
        {/* Years Established  */}
        <div className="form-group">
          <label htmlFor="established">years established</label>
          <select
            name="established"
            id="established"
            onChange={handleChange}
            className="form-control"
            value={established}
          >
            {people}
          </select>
        </div>
        {/* end of Years Establsihed */}
        {/* business price */}
        <div className="form-group">
          <label htmlFor="price">asking price<br></br> {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(price)}</label>
          <input
            type="range"
            name="price"
            min={minPrice}
            max={maxPrice}
            id="price"
            value={price}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        {/* end of business price slider*/}
        {/* Sq Footage size */}
        <div className="form-group">
          <label htmlFor="size">Sq footage </label>
          <div className="size-inputs">
            <input
              type="number"
              name="minSize"
              value={minSize}
              onChange={handleChange}
              className="size-input"
            />
            <input
              type="number"
              name="maxSize"
              value={maxSize}
              onChange={handleChange}
              className="size-input"
            />
          </div>
        </div>
        {/* end of sq footage */}
        {/** real estate group */}
        <div className="form-group">
           <div className="valuation">
            <input
              type="checkbox"
              name="busValuation"
              checked={busValuation}
              onChange={handleChange}
              className="busValuation"
            />
            <div className="valuation-label">
            <label htmlFor="busValuation">real estate</label></div>
          </div>
          <div className="valuation">
            <input
              type="checkbox"
              name="eqValuation"
              checked={eqValuation}
              onChange={handleChange}
              className="eqValuation"
            />
            <div className="valuation-label">
            <label htmlFor=" eqValuation">Relocatable</label></div>
            
          </div>
        </div> 
        {/* end of Valuations Checkbox Options*/}
      </form>
    </section>
  );
};

export default BusinessesFilter;
