import React from 'react';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';
// import NewCards2 from '../components/NewCards2';
import Services from '../components/Services';

function Home() {
  return (
    <>
      <HeroSection />
      {/* <NewCards2 /> */}
      
     
      <Services />
      <Footer />
    </>
  );
}

export default Home;
