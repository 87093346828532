import React from "react";
import '../components/NavbarA.css';

import {
  Navbar,
  Nav,
  NavDropdown,
  Container,

} from "react-bootstrap";


class NavigationBar extends React.Component {
  render() {
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" top="fixed">
        <Container>
          <Navbar.Brand href="/">DBI</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/leadership">Leadership</Nav.Link>
              <Nav.Link href="/difference">How We Are Different</Nav.Link>
              <Nav.Link href="/businesses/">Our Businesses</Nav.Link>
              <NavDropdown title="Services" id="collapsible-nav-dropdown">
                <NavDropdown.Item href="/owners">Owners</NavDropdown.Item>
                <NavDropdown.Item href="/buyers">Buyers</NavDropdown.Item>
                {/* <NavDropdown.Item href="/servicespage">DBI Commitment</NavDropdown.Item> */}
                <NavDropdown.Divider />
                <NavDropdown.Item href="/valuationservices">Valuations</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <Nav.Link href="#">833-717-7559</Nav.Link>
              {/* <Nav.Link href="/signup">Sign-Up</Nav.Link> */}
              {/* <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
        </Navbar>
    );
  }
}
export default NavigationBar;
