import React from 'react';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';
import NewCards from '../components/NewCards';

function NewHome() {
  return (
    <>
      <HeroSection />
      <NewCards />
      <Footer />
    </>
  );
}

export default NewHome;
